import { get as getItem, set as setItem } from 'local-storage'
import { BLOCKCHAIN_BSC, BLOCKCHAIN_TRX, CURRENCY_USDT, LOCALSTORAGE_KEY } from 'shared/constants'
import { create } from 'zustand'

import { composeCurrency } from 'shared/utils/billing'

const STORAGE_KEY = `${LOCALSTORAGE_KEY}_purchase`

export const AUTH_TYPE_TELEGRAM = 'telegram'
export const AUTH_TYPE_EMAIL = 'email'

const defaultState = [
  {
    number: 1,
    path: '',
    label: 'Метод оплаты',
    active: true,
    passed: false,
    payload: {
      amount: '',
      currencyAmount: '',
      blockchain: BLOCKCHAIN_TRX,
      currency: CURRENCY_USDT,
      blockchainReceipt: BLOCKCHAIN_BSC,
      errors: {},
    },
  },
  {
    number: 2,
    path: 'auth',
    label: 'Авторизация',
    active: false,
    passed: false,
    payload: {
      user: {
        authKey: null,
        login: null,
        email: null,
        profile: {
          avatar: null,
          firstName: null,
          lastName: null,
        },
      },
      type: AUTH_TYPE_TELEGRAM,
      waiting: false,
      cancel: false,
      ssid: null,
      tgStatus: null,
    },
  },
  {
    number: 3,
    path: 'waiting',
    label: 'Ожидание',
    active: false,
    passed: false,
    payload: {},
  },
  {
    number: 4,
    path: 'confirm',
    label: 'Подтверждение',
    active: false,
    passed: false,
    payload: {},
  },
]

const initState = getItem(STORAGE_KEY) || defaultState

const useStore = create((set) => ({
  steps: initState,
  setActiveStep: (num, payload = {}) =>
    set((state) => ({
      steps: state.steps.map((step) => {
        if (step.number === num) {
          return { ...step, active: true, payload: { ...step.payload, ...payload } }
        }
        return { ...step, active: false, passed: step.number < num }
      }),
    })),
  updateStep: (num, payload = {}) =>
    set((state) => ({
      steps: state.steps.map((step) => {
        return step.number === num ? { ...step, payload: { ...step.payload, ...payload } } : step
      }),
    })),
  resetSteps: () =>
    set(() => ({
      steps: defaultState,
    })),
}))

useStore.subscribe((state) => setItem(STORAGE_KEY, state.steps))

export default useStore
