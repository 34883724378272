import ky from 'ky'
import ls from 'local-storage'
import { API_BASE_URL, LOCALSTORAGE_KEY } from 'shared/constants'

export const get = async (endpoint, withAuth = false) => {
  return fetch(endpoint, 'get', null, withAuth)
}

export const post = async (endpoint, payload, withAuth = false) => {
  return fetch(endpoint, 'post', payload, withAuth)
}

const fetch = async (endpoint, method = 'get', payload = null, withAuth = false, opts = {}) => {
  const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (withAuth) {
    const { accessToken } = ls.get(LOCALSTORAGE_KEY) || {}

    if (!accessToken) {
      throw new Error('Access token not defined.')
    }

    baseHeaders.Authorization = 'Bearer ' + accessToken
  }

  opts = {
    method,
    prefixUrl: API_BASE_URL,
    headers: {
      ...baseHeaders,
      ...(opts.headers || {}),
    },
    hooks: {
      afterResponse: [
        (request, options, response) => {
          // if Unauthorized Error
          if (response.status === 401) {
            goToRefresh()
          }
          return response
        },
      ],
    },
  }

  if (payload) {
    const isComplex = payload instanceof FormData || payload instanceof URLSearchParams

    if (isComplex) {
      opts.body = payload
    } else {
      opts.json = payload
    }
  }

  try {
    const response = await ky(endpoint, opts).json()

    return response.data
  } catch (err) {
    if (err.response?.status === 422) {
      const { data } = await err.response.json()

      throw new ValidationError(data)
    }

    throw err
  }
}

const goToRefresh = () => {
  if (window === undefined) {
    return
  }

  if (window.location.pathname.match('auth/refresh') === null) {
    window.location.href = '/auth/refresh'
  }
}

export function ValidationError(errors) {
  Error.call(this, errors)
  this.name = 'ValidationError'

  this.errors = errors
  this.message = 'Error validation'
  this.status = 422

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, ValidationError)
  } else {
    this.stack = new Error().stack
  }
}

ValidationError.prototype = Object.create(Error.prototype)
