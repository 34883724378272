import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Link, scroller } from 'react-scroll'

const Footer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const scrollTarget = (target) =>
    setTimeout(() => {
      scroller.scrollTo(target, { smooth: true, offset: target === 'road-section' ? 0 : -50 })
    }, 500)

  const onClickLink = async (target) => {
    if (location.pathname !== '/') {
      await navigate('/')
      scrollTarget(target)
    }
  }

  return (
    <div id="footer" className="footer">
      <div className="footer-content">
        <div className="footer-top">
          <div className="footer-brand">
            <a href="./" className="footer-logo">
              CoinRealtor
            </a>
          </div>

          <div className="footer-menu">
            <ul className="footer-menu-list">
              <li className="footer-menu-list--item">
                <div className="footer-title">О CoinReactor</div>

                <ul className="footer-menu-links">
                  <li className="footer-menu-links--item">
                    <Link
                      href={'#'}
                      to="about-section"
                      spy={true}
                      smooth={true}
                      duration={500}
                      onClick={() => onClickLink('about-section')}
                    >
                      {t('О нас')}
                    </Link>
                  </li>
                  <li className="footer-menu-links--item">
                    <Link
                      href={'#'}
                      to="adv-section"
                      spy={true}
                      smooth={true}
                      duration={500}
                      onClick={() => onClickLink('adv-section')}
                    >
                      {t('Преимущества')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="footer-menu-list--item">
                <div className="footer-title">{t('Купить')}</div>

                <ul className="footer-menu-links">
                  <li className="footer-menu-links--item">
                    <Link
                      href={'#'}
                      to="buy-section"
                      spy={true}
                      smooth={true}
                      duration={500}
                      onClick={() => onClickLink('buy-section')}
                    >
                      {t('Токен DCGT')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="footer-menu-list--item">
                <div className="footer-title">Roadmap</div>

                <ul className="footer-menu-links">
                  <li className="footer-menu-links--item">
                    <Link
                      href={'#'}
                      to="road-section"
                      spy={true}
                      smooth={true}
                      duration={500}
                      onClick={() => onClickLink('road-section')}
                    >
                      {t('Карта')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="footer-menu-list--item">
                <div className="footer-title">{t('Новости')}</div>

                <ul className="footer-menu-links">
                  <li className="footer-menu-links--item">
                    <a href="#" disabled>
                      {t('Новости')}
                    </a>
                  </li>
                  <li className="footer-menu-links--item">
                    <a href="#" disabled>
                      {t('Блог')}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="footer-menu-list--item">
                <div className="footer-title">{t('Поддержка')}</div>

                <ul className="footer-menu-links">
                  <li className="footer-menu-links--item">
                    <a href="#" disabled>
                      FAQ
                    </a>
                  </li>
                </ul>
              </li>

              <li className="footer-menu-list--item">
                <div className="footer-title">{t('Контакты')}</div>

                <div className="footer-contacts">
                  <ul className="footer-contacts-links">
                    <li className="footer-contacts-links--item">
                      <a href="https://www.youtube.com/channel/UCM-Mwlv9WV2d2a2fHlUs-qg" target="_blank">
                        <svg width="3rem" height="2.4rem">
                          <use xlinkHref="#social-youtube" />
                        </svg>
                      </a>
                    </li>
                    <li className="footer-contacts-links--item">
                      <a href="https://t.me/CoinRealtor" target="_blank">
                        <svg width="2.9rem" height="2.4rem">
                          <use xlinkHref="#social-telegram" />
                        </svg>
                      </a>
                    </li>
                  </ul>

                  <div className="footer-contacts--feedback">
                    <Link
                      href={'#'}
                      to="contact-section"
                      spy={true}
                      smooth={true}
                      duration={500}
                      onClick={() => onClickLink('contact-section')}
                    >
                      {t('Отправить запрос')}
                    </Link>
                  </div>
                </div>
              </li>
            </ul>

            <div className="footer-feedback">
              <Link
                href="#"
                to="contact-section"
                spy={true}
                smooth={true}
                duration={500}
                className="btn btn-primary"
                onClick={() => onClickLink('contact-section')}
              >
                {t('Отправить запрос')}
              </Link>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-description">
            {t(
              'Цифровые активы подвержены ряду рисков, включая волатильность цен. Операции с цифровыми активами могут привести к значительным убыткам и могут не подходить для некоторых потребителей. Рынки цифровых активов и биржи не регулируются теми же средствами контроля или защиты клиентов, которые доступны для других форм финансовых продуктов, и зависят от меняющейся нормативной среды. Цифровые активы обычно не имеют статуса законного платежного средства и не покрываются страховкой защиты депозитов. Прошлые показатели цифрового актива не являются ориентиром для будущих показателей и не являются надежным индикатором будущих результатов или производительности.',
            )}
          </div>

          <div className="footer-service">
            <ul className="footer-service-links">
              <li className="footer-service-links--item">
                <a href="#" disabled>
                  {t('Политика конфидициальности')}
                </a>
              </li>
              <li className="footer-service-links--item">
                <a href="#" disabled>
                  {t('Соглашение о покупке токенов')}
                </a>
              </li>
              <li className="footer-service-links--item">
                <a href="#" disabled>
                  {t('Отказ от ответственности')}
                </a>
              </li>
            </ul>

            <div className="footer-copyright">© Copyright 2022</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
